import { Locale } from '../../util/LocalizationUtil';

export const GROUPED_CRE_CARD_MOV_PAGE_SIZE = 15;
export const NON_GROUPED_CRE_CARD_MOV_PAGE_SIZE = 25;
export const CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS = 366;

export interface CompleteCreCardMovRequest {
  page: number,
  pageSize: number,
  searchText?: string,
  uploadDateFrom?: string,
  uploadDateTo?: string,
  expDateFrom?: string,
  expDateTo?: string,
  state?: CreCardMovState,
  order?: string,
  desc?: boolean,
  locale?: Locale
}

export interface CompleteCreCardMovByIdRequest extends CompleteCreCardMovRequest {
  uploadId: number
}

export interface CompleteCreCardMovDto {
  creCardId: number,
  uploadId: number,
  creCardMovInsertTime: Date,
  creCardNum: string,
  staffId: number,
  collab: string,
  creCardDescription: string,
  payDate: Date,
  docNum: number,
  compAmount: number,
  currency: string,
  currAmount: number,
  exchange: number,
  creCardMovState: string,
  checked?: boolean,
  checkedNotes?: string,
  discarded?: boolean,
  discardedNotes?: string,
  creCardMovUploadTime: Date,
  expNoteId: number,
  notValid: boolean
}

export interface CompleteCreCardMovResponse {
  page: number,
  pageSize: number,
  totalPages: number,
  totalItems: number,
  elements: CompleteCreCardMovDto[]
}

export interface CreCardMovExpense {
  expNoteDesc: string,
  expenseDesc: string,
  expenseIcon: string,
  expenseDate: Date,
  currAmount: number,
  currency: string,
  expNoteTotalAmount: number
}

export interface CreCardMovInfoResponse {
  uploadId: number,
  uploadInsertDate: Date,
  creCardMovId: number,
  creCardMovInsertDate: Date,
  creCardMovPayDate: Date,
  creCardMovDesc: string,
  creCardCurrAmount: number,
  creCardCurrency: string,
  creCardCompAmount: number,
  creCardExchange: number,
  creCardNotValid: boolean,
  creCardDiscardedNotes: string,
  linkedExpenses: CreCardMovExpense[]
}

export interface CreCardMovPerOperationCounterRequest {
  searchText?: string,
  expDateFrom?: string,
  expDateTo?: string,
  state?: CreCardMovState,
  locale?: Locale
}

export interface CreCardMovPerOperationCounter {
  counter: number,
  month: number,
  year: number
}

export interface CreCardMovPerOperationCounterResponse {
  size: number,
  elements: CreCardMovPerOperationCounter[]
}

export interface CreCardMovPerUploadCounterRequest {
  searchText?: string,
  uploadDateFrom?: string,
  uploadDateTo?: string,
  state?: CreCardMovState,
  locale?: Locale
}

export interface CreCardMovPerUploadCounter {
  id: number,
  counter: number,
  day: number,
  month: number,
  year: number
}

export interface CreCardMovPerUploadCounterResponse {
  size: number,
  elements: CreCardMovPerUploadCounter[]
}

export interface LinkCreCardMovResponse {
  success: boolean
}

export enum CreCardMovState {
  ALL = 'AL',
  CONNECTED = 'CN',
  DISCONNECTED = 'DN',
  EXCLUDED = 'EX',
  CHECKED = 'CK'
}

export enum ListFilterType {
  SEARCH_TEXT,
  STATE,
  UPLOAD_DATE_FROM,
  UPLOAD_DATE_TO,
  EXP_DATE_FROM,
  EXP_DATE_TO
}

export interface ListFilter {
  type: ListFilterType,
  label: string,
  value: any
}

export enum CreCardMovStateFilters {
  ALL = 'filters.state.all',
  CONNECTED = 'filters.state.connected',
  DISCONNECTED = 'filters.state.disconnected',
  EXCLUDED = 'filters.state.excluded',
  CHECKED = 'filters.state.checked'
}

export interface Group {
  id: number,
  day: number,
  month: number,
  year: number,
  count: number
}

export enum CreCardMovActionType {
  CHECK_MOV,
  UNCHECK_MOV,
  DISCARD_MOV,
  UNDISCARD_MOV
}

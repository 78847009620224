import {Grid, Typography} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import React from "react";
import {Supplier} from "../model";
import {Controller, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  supplier: Supplier,
  enabled?: boolean
}

export const GeneralData = ({supplier, enabled}: ComponentProps) => {

  const {t} = useTranslation('supplier', {keyPrefix: 'generalData'});

  let control;
  if (enabled) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const formMethods = useFormContext();
    control = formMethods.control;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" textTransform={"uppercase"}>{t('title')}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {enabled ? (
          <Controller
            name="vatId"
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                required
                label={t('label.vatNumber')}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        ) : (
          <TextFieldZts
            disabled
            label={t('label.vatNumber')}
            value={supplier.vatId}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {enabled ? (
          <Controller
            name="description"
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                required
                label={t('label.description')}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        ) : (
          <TextFieldZts
            disabled={true}
            label={t('label.description')}
            value={supplier.description}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        {enabled ? (
          <Controller
            name="erpCode"
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                required
                label={t('label.erpCode')}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        ) : (
          <TextFieldZts
            disabled={true}
            label={t("label.erpCode")}
            value={supplier.erpCode}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        {enabled ? (
          <Controller
            name="address"
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                label={t("label.address")}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        ) : (
          <TextFieldZts
            disabled={true}
            label={t("label.address")}
            value={supplier.address}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        {enabled ? (
          <Controller
            name="notes"
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                label={t("label.notes")}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        ) : (
          <TextFieldZts
            disabled={true}
            label={t("label.notes")}
            value={supplier.notes}
          />
        )}
      </Grid>

    </Grid>
  );
}
